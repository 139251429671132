import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  constructor(public service: ServiceService, public route: Router) { }

  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      oldPassword: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]),
      confirmPassword: new FormControl('', Validators.required)
    })
  }

  // reset password
  resetPasswordFunc() {
    var apireq = {
      'newPassword': this.resetPasswordForm.value.confirmPassword,
      'oldPassword': this.resetPasswordForm.value.oldPassword
    }
    this.service.showSpinner();
    this.service.post('account/change-password', apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc('Password updated successfully.');
        this.route.navigate(['/login'])
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.toasterErr('Unauthorized Access.');
      } else {
        this.service.toasterErr('Something Went Wrong.');
      }
    })
  }
}
