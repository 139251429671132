import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
declare var $: any

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  userDetail: any = {};
  profileData: any = {};
  qrCode: any;
  secretKey: any;
  googleAuth: any = "";
  smsAuth: any = "";
  ipAddress: string;
  emailAuth: any = "";

  copyToClipboard(item) {
    this.service.toasterSucc("Secret key copied");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.myProfile();
  }

  // get profile
  myProfile() {
    var url = 'account/my-account';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      if (res['status'] == 200) {
        this.userDetail = res['data']
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // navigate to change password
  changePassword() {
    this.router.navigate(['/change-password']);
  }



  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }

  // Get Profile for 2fa status
  getProfile() {
    this.service.showSpinner();
    this.service.get(`account/my-account`).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.profileData = res["data"];
          this.service.name = this.profileData.firstName;
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err["message"]);
      }
    );
  }

  // Enable Google Auth Functionality
  enableGoogleAuth() {
    if (
      this.profileData.twoFaType == "SKIP" ||
      this.profileData.twoFaType == "NONE"
    ) {
      this.service.showSpinner();
      this.service.get("account/google-auth").subscribe(
        (res) => {
          if (res["status"] == 200) {
            this.qrCode = res["data"]["qrCode"];
            this.secretKey = res["data"]["secretKey"];
            $("#googleAuth").modal({ backdrop: "static", keyboard: false });
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else {
      this.service.toasterErr("Disable SMS Auth First");
    }
  }

  verifyGoogleAuth() {
    var url = "account/verify-google-code";
    var apireq = {
      code: this.googleAuth,
      secretKey: this.secretKey,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postApi(url, apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.qrCode = "";
          this.secretKey = "";
          this.googleAuth = "";
          this.getProfile();
          this.service.toasterSucc("Google Auth Enabled Successfully");
          $("#googleAuth").modal("hide");
        } else {
          this.service.toasterErr("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("Something Went Wrong");
      }
    );
  }

  //Disable Google Security
  openGoogleAuthModal() {
    this.googleAuth = "";
    $("#googleAuthDisable").modal({ backdrop: "static" });
  }
  disableTwoFA() {
    var apireq = {
      otp: this.googleAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postApi("account/twoFa-disable", apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#googleAuthDisable").modal("hide");
          this.getProfile();
          this.googleAuth = "";
          this.service.toasterSucc("Google Auth Disabled Successfully");
        } else {
          this.service.toasterErr("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err["message"]);
      }
    );
  }



 

  logoutFunc() {
    localStorage.removeItem("credential");
    localStorage.removeItem("profiledata");
    this.router.navigate(["/login"]);
  }
  // Enable SMS Auth Functionality
  openSMSAuthModal() {
    this.smsAuth = "";
    if (
      this.profileData.twoFaType != "GOOGLE" ||
      this.profileData.twoFaType != "EMAIL"
    ) {
      this.service.showSpinner();
      this.service.get("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal({ backdrop: "static" });
            this.service.toasterSucc(res["message"]);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.toasterErr("Mobile Number Not Verified");
          } else {
            this.service.toasterErr(err["message"]);
          }
        }
      );
    } else {
      this.service.toasterErr("Disable Google Auth First");
    }
  }

  verifySMSAuth() {
    var url = "account/verify-sms-code";
    var smsapireq = {
      otp: this.smsAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postApi(url, smsapireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          this.getProfile();
          this.service.toasterSucc(res["message"]);
          $("#smsAuth").modal("hide");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("Something Went Wrong");
      }
    );
  }

  // Disable SMS Auth Functionality
  disableSMSAuth() {
    var apireq = {
      otp: this.smsAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service
      .postApi("account/sms-auth-disable", apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
            this.getProfile();
            this.smsAuth = "";
            $("#smsAuth").modal("hide");
            this.service.toasterSucc("SMS Auth Disabled Successfully");
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.toasterErr(err["message"]);
        }
      );
  }

  // email enable authentication

  openEMAILAuthModal() {
    this.emailAuth = "";
    if (
      this.profileData.twoFaType != "GOOGLE" ||
      this.profileData.twoFaType != "SMS"
    ) {
      this.service.showSpinner();
      this.service.get("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal({ backdrop: "static" });
            this.service.toasterSucc(res["message"]);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.toasterErr("Email Address Not Verified");
          } else {
            this.service.toasterErr(err["message"]);
          }
        }
      );
    } else {
      this.service.toasterErr(
        "Disable Google Auth First" || "Disable SMS Auth First"
      );
    }
  }

  // verify email authenicator
  verifyEmailAuth() {
    var url = "account/verify-Email-Sms-code";
    // var url = 'account/get-user-login-details?userIdForLoginHistoy=' + this.profiledetails.userId ;

    // var url = 'account/verify-user?token=' + this.emailAuth
    var smsapireq = {
      emailOtp: this.emailAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postApi(url, smsapireq).subscribe(
      (res) => {
        // this.service.getCandyPixelForms(url).subscribe(res => {

        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.emailAuth = "";
          this.getProfile();
          this.service.toasterSucc("Email Auth Enable Successfully");
          $("#emailAuth").modal("hide");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("Something Went Wrong");
      }
    );
  }
  // end email authenticator

  // Disable email Auth Functionality
  disableEmailAuth() {
    var apireq = {
      emailOtp: this.emailAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service
      .postApi("account/email-sms-auth-disable", apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            this.getProfile();
            this.smsAuth = "";
            $("#emailAuth").modal("hide");
            this.service.toasterSucc("Email Auth Disabled Successfully");
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.toasterErr(err["message"]);
        }
      );
  }
  // end disable email auth



  // get IP Address
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }
}
