import { logging } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any

@Component({
  selector: 'app-view-deposit',
  templateUrl: './view-deposit.component.html',
  styleUrls: ['./view-deposit.component.css']
})
export class ViewDepositComponent implements OnInit {

  userKycId: any;
  kycData: any = {};
  kycDocList: any = [];
  rejectionReason: string = '';
  documentId: any;
  username: any;
  userId: any;
  documentIdd: any;
  kycIdd: any;
  kycStatus: string;
  ButtonDisable: any;
  data: any;
  fiatId: any
  fiatData: any
  complete: any
  pending: any
  fiatStatus: any
  utrNo: any
  reject: any
  constructor(public activatedRoute: ActivatedRoute, public service: ServiceService, private router: Router) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.fiatId = res.fiatID
      this.fiatStatus = res.fiatStatus;
      this.utrNo = res.utrNo
    })
  }

  ngOnInit() {
    console.log(this.fiatId);

    this.view()

  }
  back() {
    this.router.navigate(['/list-fund'])
  }

  approveKyc(status) {
    this.complete = status
    $('#approveModal').modal('show')
  }

  openModal(status) {
    this.pending = status
    $('#reject').modal('show')
  }

  rejectKyc(status) {
    this.reject = status
    $('#reject').modal('hide')
  }

  view() {
    let url = `wallet/admin/fiat-deposit-view?fiatid=${this.fiatId}`
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.fiatData = res.data
      } else {
        this.service.toasterErr(res.message)
      }
    }, err => {
      this.service.toasterErr(err.error.messsage)
    })
  }

  approve() {
    let data = {}
    this.service.post(`wallet/admin/Deposit-Inr-Status?fiatStatus=${this.complete}&utrNo=${this.utrNo}`, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.view()
        $('#approveModal').modal('hide')
      }
    })
  }

}
