import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { IMyDpOptions } from "mydatepicker";
declare var $
@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.css']
})
export class EditStaffComponent implements OnInit {
  editStaffForm: FormGroup;
  id: any;
  staffDetails: any;
  ipAddress: any;
  staffPrivileges: any = [];
  newArr: any = [];
  checkedBoolead: boolean = true;
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  CountryCodeList: any;
  products: any = [];
  arr: any = [];

  editImage: any;
  imageData: any = "assets/img/profile-img.jpg";
  type_pass: any = "password";
  type_pass2: any = "password";
//  moonLanding = new Date('June 23, 04 00:20:18');
  today = new Date();
  eighteenYearsAgoAge = this.today.setFullYear(this.today.getFullYear()-18);
  
//   eighteenYearsAgo = new Date();
// eighteenYearsAgoAge = this.eighteenYearsAgo.setFullYear(this.eighteenYearsAgo.getFullYear()-18);
  constructor(public router: Router, public param: ActivatedRoute, public service: ServiceService, public http: HttpClient) {
    this.param.queryParams.subscribe((res) => {
      this.id = res.id;
    });
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        this.ipAddress = data
      })
  

      console.log(this.eighteenYearsAgoAge);

  }


  ngOnInit() {
 


  console.log(this.eighteenYearsAgoAge);
    this.editStaffForm = new FormGroup({
      firstName: new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      lastName: new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      gender: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^[1-9][0-9]{8,13}$/), Validators.maxLength(18)]),
      dob: new FormControl("", Validators.required),
      selectCountry: new FormControl("", Validators.required),
      selectState: new FormControl("", Validators.required),
      selectCity: new FormControl("", [Validators.required]),
      zipCode: new FormControl(""),
      address: new FormControl("", [
        Validators.maxLength(256),
        Validators.required,
      ]),
      countryCode: new FormControl("+91", [Validators.required]),
      // password: new FormControl("", [
      //   Validators.required,
      //   Validators.minLength(8),
      //   Validators.pattern(
      //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i
      //   ),
      // ]),
      // confirmPassword: new FormControl("", Validators.required),
      dashboardChecked: new FormControl(''),
      staffChecked: new FormControl(''),
      hotWalletChecked: new FormControl(''),
      kycChecked: new FormControl(''),
      walletChecked: new FormControl(''),
      staticChecked: new FormControl(''),
      logsChecked: new FormControl(''),
      ticketChecked: new FormControl(''),
      tradeChecked: new FormControl(''),
      disputeChecked: new FormControl(''),
      bankChecked: new FormControl(''),
      feeChecked: new FormControl(''),
      escrowChecked:new FormControl(''),
      feedbackChecked :new FormControl(''),
      usermanagementChecked:new FormControl(''),
      usermanagementofexchangeChecked:new FormControl(''),
      advertiesmentChecked:new FormControl(''),
      faqChecked: new FormControl(''),
      settingChecked: new FormControl(''),
      fundChecked : new FormControl(''),
      xindiaChecked : new FormControl('')
    });

    this.onDateChanged();
    this.countryList = this.service.countryListJson;
    this.http.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
    this.getProfile();

  
  }
// Get StateList Functionality
getStateList(countryCode) {
  var stateList = this.countryList.filter((x) => x.country == countryCode);
    console.log(stateList);
    if(stateList.length){
      this.stateList = stateList[0].states ? stateList[0].states : '';
      this.countryCode = stateList[0].code ? stateList[0].code : '';
    }
}

// Date of Birth Management
public myDatePickerOptions: IMyDpOptions = {
  dateFormat: "yyyy-mm-dd",
  editableDateField: false,
  openSelectorOnInputClick: false,
  disableSince: { year: 0, month: 0, day: 0 },
  height: "33px",
  // background : '#000'
};
onDateChanged() {
  let d = new Date();
  let copy1 = this.getCopyOfOptions();
  copy1.disableSince = {
    year: d.getFullYear() - 18,
    month: d.getMonth() + 1,
    day: d.getDate(),
  };
  this.myDatePickerOptions = copy1;
}
//Returns copy of myDatePickerOptions
getCopyOfOptions(): IMyDpOptions {
  return JSON.parse(JSON.stringify(this.myDatePickerOptions));
}
  getProfile() {
    this.service.showSpinner();
    let dataa = {
      "primaryIdCommonPerRequest": this.id
    }
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res) => {
      this.staffDetails = res['data']['staffDetails'];
      this.staffPrivileges = res['data']['staffPrivileges'];
      this.staffPrivileges.forEach(element => {

        if (element == 'DASHBOARD') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            dashboardChecked: this.checkedBoolead,
          })
        }
        else if (element == 'USER_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            usermanagementChecked: this.checkedBoolead,
          })
        }
        else if (element == 'EXCHANGE_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            usermanagementofexchangeChecked: this.checkedBoolead,
          })
        }
        else if (element == 'HOT_COLD_LIMIT_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            hotWalletChecked: this.checkedBoolead,
          })
        }
        else if (element == 'ADVERTISEMENTS') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            advertiesmentChecked: this.checkedBoolead,
          })
        }
        else if (element == 'FUND_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            fundChecked: this.checkedBoolead,
          })
        }
        else if (element == 'XINDIA_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            xindiaChecked: this.checkedBoolead,
          })
        }
        else if (element == 'FAQ_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            faqChecked: this.checkedBoolead,
          })
        }
        else if (element == 'SETTINGS') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            settingChecked: this.checkedBoolead,
          })
        }
        else if (element == 'KYC_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            kycChecked: this.checkedBoolead,
          })
        }
        else if (element == 'WALLET_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            walletChecked: this.checkedBoolead,
          })
        }
        else if (element == 'STATIC_CONTENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            staticChecked: this.checkedBoolead,
          })
        }
        else if (element == 'LOGS_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            logsChecked: this.checkedBoolead,
          })
        }
        else if (element == 'TICKET_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            ticketChecked: this.checkedBoolead,
          })
        }
        else if (element == 'TRADE_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            tradeChecked: this.checkedBoolead,
          })
        }
        else if (element == 'DISPUTE_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            disputeChecked: this.checkedBoolead,
          })
        }
        else if (element == 'STAFF_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            staffChecked: this.checkedBoolead,
          })
        }
        else if (element == 'FEE_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            feeChecked: this.checkedBoolead,
          })
        }
        else if (element == 'BANK_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            bankChecked: this.checkedBoolead,
          })
        }
        else if (element == 'FEEDBACK_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            feedbackChecked: this.checkedBoolead,
          })
        }
        else if (element == 'ESCROW_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            escrowChecked: this.checkedBoolead,
          })
        }
      });
      let index = this.arr.findIndex(
        (x) => x.country == this.staffDetails.country
      );
      // this.stateList = this.arr[index].states;
      // this.mobileData = this.staffDetails.phoneNo.split('-');
      this.service.name = this.staffDetails.firstName;
      // this.getStateList(this.staffDetails.country);
         // if we get country then we will find states of that country
         this.staffDetails.country ? this.getStateList(this.staffDetails.country) : null || "string";
         setTimeout(() => {
      this.editStaffForm.patchValue({
        firstName: this.staffDetails.firstName,
        lastName: this.staffDetails.lastName,
        email: this.staffDetails.email,
        gender: this.staffDetails.gender,
        role: this.staffDetails.role,
        phoneNumber: this.staffDetails.phoneNo,
        selectCountry : this.staffDetails.country,
        selectState : this.staffDetails.state,
        dob : this.staffDetails.dob,
        countryCode: this.staffDetails.countryCode,
        selectCity : this.staffDetails.city,
        address : this.staffDetails.address,
        zipCode: this.staffDetails.zipCode
      })
      this.service.hideSpinner();
    }, 1000);
    this.editImage = this.staffDetails.imageUrl;

    })
  }

  editStaff() {
    let data = {
      "address": this.editStaffForm.value.address,
      "city": this.editStaffForm.value.selectCity,
      "country": this.editStaffForm.value.selectCountry,
      "countryCode": this.editStaffForm.value.countryCode,
      "dob": this.editStaffForm.value.dob,
      "email": this.editStaffForm.value.email,
      "firstName": this.editStaffForm.value.firstName,
      "gender": this.editStaffForm.value.gender,
      "imageUrl": this.editImage ? this.editImage : this.staffDetails.imageUrl,
      "ipAddress": this.ipAddress.ip,
      "lastName": this.editStaffForm.value.lastName,
      "location": "string",
      "phoneNo": this.editStaffForm.value.phoneNumber,
      "previlage": this.newArr,
      "roleStatus": this.editStaffForm.value.role,
      'zipCode': this.editStaffForm.value.zipCode,

      "state": this.editStaffForm.value.selectState,
      "webUrl": "string",
      "userIdToUpdate": this.id
    }
    this.service.showSpinner()
    this.service.post('account/admin/user-management/edit-sub-admin', data).subscribe((res) => {
      this.service.hideSpinner()
      if(res['status'] == 200){
        this.service.toasterSucc(res['message'])
        this.router.navigate(['/staff-management'])
      }
    })
  }

  /** Function for checkbox click */
  checkboxClick(value, checked) {
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
      this.newArr.splice(index, 1)
    }
  }
// Image Functionality Start Here
uploadImg($event): void {
  var img = $event.target.files[0];
  this.uploadImageFunc(img);
}
uploadImageFunc(img) {
  var fb = new FormData();
  fb.append("file", img);
  this.service.showSpinner();
  this.service.postApi("account/upload-file", fb).subscribe(
    (res) => {
      // this.service.hideSpinner();
      if (res["status"] == "200") {
        this.editImage = res["data"];
        this.service.hideSpinner();
      }
    },
    (err) => {
      this.service.hideSpinner();
      if (err["status"] == "401") {
        this.service.onLogout();
        this.service.toasterErr("Unauthorized Access");
      } else {
        this.service.toasterErr("Something Went Wrong");
      }
    }
  );
}

switchEye() {
  this.type_pass = "text";
}

switchToText() {
  this.type_pass = "password";
}

// to switch eye
switchEye2() {
  this.type_pass2 = "text";
}

switchToText2() {
  this.type_pass2 = "password";
}
}
