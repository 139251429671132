import { NomineeManagementComponent } from './nominee-management/nominee-management.component';
import { ViewDepositComponent } from './funds-management/view-deposit/view-deposit.component';
import { ReferralComponent } from './referral/referral.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { MarketManagementComponent } from './market-management/market-management.component';
import { SettingComponent } from './setting/setting.component';
import { SubAdminManagementComponent } from './sub-admin-management/sub-admin-management.component';
import { WalletManagementComponent } from './wallet-management/wallet-management.component';
import { StaticsContentComponent } from './statics-content/statics-content.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermAndServiceComponent } from './term-and-service/term-and-service.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ExchangeManagementComponent } from './exchange-management/exchange-management.component';
import { PaymentAndTransactionComponent } from './payment-and-transaction/payment-and-transaction.component';
import { KycManagementComponent } from './kyc-management/kyc-management.component';
import { TicketManagementComponent } from './ticket-management/ticket-management.component';
import { AddWalletAddressComponent } from './add-wallet-address/add-wallet-address.component';
import { WithdrawlFeeComponent } from './withdrawl-fee/withdrawl-fee.component';
import { DailyLimitComponent } from './daily-limit/daily-limit.component';
import { WithdrawlLimitComponent } from './withdrawl-limit/withdrawl-limit.component';
import { PrevilageSettingComponent } from './previlage-setting/previlage-setting.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { BannerSettingComponent } from './banner-setting/banner-setting.component';
import { WebsiteContentSettingComponent } from './website-content-setting/website-content-setting.component';
import { KycActionPageComponent } from './kyc-action-page/kyc-action-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FiatManagementComponent } from './fiat-management/fiat-management.component';
import { FeeManagementComponent } from './fee-management/fee-management.component';
import { HotcoldwalletManagementComponent } from './hotcoldwallet-management/hotcoldwallet-management.component';
import { ViewtransactionComponent } from './viewtransaction/viewtransaction.component';
import { TradeManagementComponent } from './trade-management/trade-management.component';
import { DisputeManagementComponent } from './dispute-management/dispute-management.component';
import { TradeDetailsComponent } from './trade-details/trade-details.component';
import { DisputeTradeDetailsComponent } from './dispute-trade-details/dispute-trade-details.component';
import { CreateSubadminComponent } from './create-subadmin/create-subadmin.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { EnquireManagementComponent } from './enquire-management/enquire-management.component';
import { WalletdetailsComponent } from './walletdetails/walletdetails.component';
import { StaffManagementComponent } from './staff-management/staff-management.component';
import { AddNewStaffComponent } from './add-new-staff/add-new-staff.component';
import { EditStaffComponent } from './edit-staff/edit-staff.component';
import { ViewStaffComponent } from './view-staff/view-staff.component';
import { FooterComponent } from './footer/footer.component';
import { AdminManagementComponent } from './admin-management/admin-management.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { EditAdminComponent } from './edit-admin/edit-admin.component';
import { ViewAdminComponent } from './view-admin/view-admin.component';
import { UserManagementExchangeComponent } from './user-management-exchange/user-management-exchange.component';
import { ViewUserManagementExchangeComponent } from './view-user-management-exchange/view-user-management-exchange.component';
import { ViewUserManagementExchangeOfFeedbackComponent } from './view-user-management-exchange-of-feedback/view-user-management-exchange-of-feedback.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TakerMakerFeeComponent } from './taker-maker-fee/taker-maker-fee.component';
import { BankManagementComponent } from './bank-management/bank-management.component';
import { LogsManagementComponent } from './logs-management/logs-management.component';
import { AdvertisementManagementComponent } from './advertisement-management/advertisement-management.component';
import { AdvertisementDetailsComponent } from './advertisement-details/advertisement-details.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { TicketReplyComponent } from './ticket-reply/ticket-reply.component';
import { EscrowManagementComponent } from './escrow-management/escrow-management.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FeedbackListComponent } from './feedback-management/feedback-list/feedback-list.component';
import { FeedbackViewComponent } from './feedback-management/feedback-view/feedback-view.component';
import { FaqComponent } from './faq-management/faq/faq.component';
import { EditFaqComponent } from './faq-management/edit-faq/edit-faq.component';
import { ViewFaqComponent } from './faq-management/view-faq/view-faq.component';
import { AddFaqComponent } from './faq-management/add-faq/add-faq.component';
import { ListBlogComponent } from './blog-management/list-blog/list-blog.component';
import { AddBlogComponent } from './blog-management/add-blog/add-blog.component';
import { ViewBlogComponent } from './blog-management/view-blog/view-blog.component';
import { EditBlogComponent } from './blog-management/edit-blog/edit-blog.component';
import { StaticContentAddComponent } from './statics-content/static-content-add/static-content-add.component';
import { ListAnnouncementComponent } from './announcement-management/list-announcement/list-announcement.component';
import { AddAnnouncementComponent } from './announcement-management/add-announcement/add-announcement.component';
import { EditAnnouncementComponent } from './announcement-management/edit-announcement/edit-announcement.component';
import { ViewAnnouncementComponent } from './announcement-management/view-announcement/view-announcement.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { ContactusManagementComponent } from './contactus-management/contactus-management.component';
import { CommunityAddComponent } from './community-management/community-add/community-add.component';
import { CommunityEditComponent } from './community-management/community-edit/community-edit.component';
import { CommunityListComponent } from './community-management/community-list/community-list.component';
import { CarrerCategoryListComponent } from './career-management/carrer-category-list/carrer-category-list.component';
import { CarrerCategoryAddComponent } from './career-management/carrer-category-add/carrer-category-add.component';
import { CareerCategoryEditComponent } from './career-management/career-category-edit/career-category-edit.component';
import { CareerCategoryViewComponent } from './career-management/career-category-view/career-category-view.component';
import { ListingUserListComponent } from './listing-managment/listing-user-list/listing-user-list.component';
import { CareerSubcategoryListComponent } from './career-subcategory-list/career-subcategory-list.component';
import { CareerSubcategoryAddComponent } from './career-subcategory-add/career-subcategory-add.component';
import { CareerSubcategoryViewComponent } from './career-subcategory-view/career-subcategory-view.component';
import { CareerSubcategoryEditComponent } from './career-subcategory-edit/career-subcategory-edit.component';
import { ListingUserViewComponent } from './listing-managment/listing-user-view/listing-user-view.component';
import { UserDeatilsNomineeComponent } from './user-deatils-nominee/user-deatils-nominee.component';
import { ContactusViewComponent } from './contactus-management/contactus-view/contactus-view.component';
import { ListingCoinComponent } from './listing-coin/listing-coin.component';
import { ManageCoinListComponent } from './manage-coin-list/manage-coin-list.component';
import { TokenManagementComponent } from './token-management/token-management.component';
import { FundsListComponent } from './funds-management/funds-list/funds-list.component';
import { AdminBankComponent } from './bank-management/admin-bank/admin-bank.component';
import { AdminBankListComponent } from './bank-management/admin-bank-list/admin-bank-list.component';
import { AdminBankViewComponent } from './bank-management/admin-bank-view/admin-bank-view.component';
import { ViewFundWithdrawComponent } from './funds-management/view-fund-withdraw/view-fund-withdraw.component';
import { ViewFundUpiComponent } from './funds-management/view-fund-upi/view-fund-upi.component';
import { ListingCoinEditComponent } from './listing-coin-edit/listing-coin-edit.component';
import { AddTeamComponent } from './team-management/add-team/add-team.component';
import { EditTeamComponent } from './team-management/edit-team/edit-team.component';
import { ListTeamComponent } from './team-management/list-team/list-team.component';
import { ViewTeamComponent } from './team-management/view-team/view-team.component';
import { XindiaDetailsListComponent } from './xindia-management/xindia-details-list/xindia-details-list.component';
import { XindiaDetailsAddComponent } from './xindia-management/xindia-details-add/xindia-details-add.component';
import { XindiaHoldingAddComponent } from './xindia-management/xindia-holding-add/xindia-holding-add.component';
import { AddXindiaTableComponent } from './xindia-management/add-xindia-table/add-xindia-table.component';
import { EditXindiaTableComponent } from './xindia-management/edit-xindia-table/edit-xindia-table.component';
import { ViewXindiaTableComponent } from './xindia-management/view-xindia-table/view-xindia-table.component';
import { XindiaBurnedstatusComponent } from './xindia-management/xindia-burnedstatus/xindia-burnedstatus.component';
import { ListPartnershipComponent } from './partnership-management/list-partnership/list-partnership.component';
import { AddPartnershipComponent } from './partnership-management/add-partnership/add-partnership.component';
import { EditPartnershipComponent } from './partnership-management/edit-partnership/edit-partnership.component';
import { ViewPartnershipComponent } from './partnership-management/view-partnership/view-partnership.component';
import { ListBannerComponent } from './banner-management/list-banner/list-banner.component';
import { AddBannerComponent } from './banner-management/add-banner/add-banner.component';
import { EditBannerComponent } from './banner-management/edit-banner/edit-banner.component';
import { ViewBannerComponent } from './banner-management/view-banner/view-banner.component';
import { BeforeLoginGuardGuard } from './before-login-guard.guard';
import { AuthGuard } from './auth.guard';
import { CommissionManagementListComponent } from './commission-management/commission-management-list/commission-management-list.component';
import { NomineeViewComponent } from './nominee-view/nominee-view.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [BeforeLoginGuardGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [BeforeLoginGuardGuard]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [BeforeLoginGuardGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'market-management/:action',
    component: MarketManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setting',
    component: SettingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sub-admin-management',
    component: SubAdminManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'wallet-management',
    component: WalletManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'statics-content',
    component: StaticsContentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'about-us/:id',
    component: AboutUsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'term-and-service/:id',
    component: TermAndServiceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'privacy/:id',
    component: PrivacyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'disclaimer/:id',
    component: DisclaimerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'exchange-management',
    component: ExchangeManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-and-transaction',
    component: PaymentAndTransactionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'kyc-management',
    component: KycManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ticket-management',
    component: TicketManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ticket-details/:id',
    component: TicketDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ticket-reply/:id',
    component: TicketReplyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-wallet-address',
    component: AddWalletAddressComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'withdrawl-fee',
    component: WithdrawlFeeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'daily-limit',
    component: DailyLimitComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'withdrawl-limit',
    component: WithdrawlLimitComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'previlage-setting',
    component: PrevilageSettingComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'my-profile',
    component: MyProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contactUs', component: ContactusManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contactus-view', component: ContactusViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'banner-setting',
    component: BannerSettingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list-banner', component: ListBannerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-banner', component: AddBannerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-banner', component: ViewBannerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-banner', component: EditBannerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'website-content-setting/:id',
    component: WebsiteContentSettingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'kyc-action/:id',
    component: KycActionPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fiat-management',
    component: FiatManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fee-management',
    component: FeeManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'hotcoldwallet-management',
    component: HotcoldwalletManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'viewtransaction/:id',
    component: ViewtransactionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-details',
    component: UserDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'document-details/:id',
    component: DocumentDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'trade-management',
    component: TradeManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dispute-management',
    component: DisputeManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'trade-details/:id',
    component: TradeDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dispute-trade-details/:id',
    component: DisputeTradeDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-subadmin',
    component: CreateSubadminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profit-loss',
    component: ProfitLossComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'enquiry-management',
    component: EnquireManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'walletdetails/:id',
    component: WalletdetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'staff-management',
    component: StaffManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-new-staff',
    component: AddNewStaffComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-staff',
    component: EditStaffComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-staff',
    component: ViewStaffComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'footer',
    component: FooterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-management',
    component: AdminManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-admin',
    component: AddAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-admin',
    component: EditAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-admin/:id',
    component: ViewAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-management-exchange',
    component: UserManagementExchangeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-user-management-exchange',
    component: ViewUserManagementExchangeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-user-management-exchange-of-feedback',
    component: ViewUserManagementExchangeOfFeedbackComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'taker-maker-fee',
    component: TakerMakerFeeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'category-list', component: CarrerCategoryListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'category-add', component: CarrerCategoryAddComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'category-edit', component: CareerCategoryEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'category-view', component: CareerCategoryViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'listing-user', component: ListingUserListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'listing-view', component: ListingUserViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'faq-management', component: FaqComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-faq', component: AddFaqComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-faq', component: EditFaqComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-faq', component: ViewFaqComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list-blog', component: ListBlogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-blog', component: AddBlogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-blog', component: ViewBlogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-blog', component: EditBlogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-static', component: StaticContentAddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list-announcement', component: ListAnnouncementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-announcement', component: AddAnnouncementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-announcement', component: EditAnnouncementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-announcement', component: ViewAnnouncementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'community-list', component: CommunityListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'community-add', component: CommunityAddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'community-edit', component: CommunityEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partner/:id', component: PartnershipComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'logs-management',
    component: LogsManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'advertisement-management',
    component: AdvertisementManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'advertisement-details/:id',
    component: AdvertisementDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bank-management',
    component: BankManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'escrow-management',
    component: EscrowManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'feedback-list', component: FeedbackListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'feedback-view', component: FeedbackViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'career-subcat-list', component: CareerSubcategoryListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'career-subcat-edit', component: CareerSubcategoryEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'career-subcat-add', component: CareerSubcategoryAddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'career-subcat-view', component: CareerSubcategoryViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'nominee-view', component: UserDeatilsNomineeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'listing-coin', component: ListingCoinComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-coin', component: ManageCoinListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'token-manage', component: TokenManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list-fund', component: FundsListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-bank-details', component: AdminBankComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-bank-list', component: AdminBankListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bank-view', component: AdminBankViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fund-view', component: ViewFundWithdrawComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'upi-view', component: ViewFundUpiComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-coin', component: ListingCoinEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-team', component: AddTeamComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-team', component: EditTeamComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-team', component: ViewTeamComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list-team', component: ListTeamComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'xindia-details-list', component: XindiaDetailsListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'xindia-details-add', component: XindiaDetailsAddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'xindia-holding-add', component: XindiaHoldingAddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'xindia-tab-add', component: AddXindiaTableComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'xindia-tab-edit', component: EditXindiaTableComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'xindia-tab-view', component: ViewXindiaTableComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'burned-status', component: XindiaBurnedstatusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list-partner', component: ListPartnershipComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-partner', component: AddPartnershipComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-partner', component: EditPartnershipComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-partner', component: ViewPartnershipComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'commission-management', component: CommissionManagementListComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'referral', component: ReferralComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-deposit', component: ViewDepositComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-with', component: ViewDepositComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'nominee', component: NomineeManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-nominee', component: NomineeViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
